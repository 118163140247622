// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logistic-image-js": () => import("./../../../src/pages/logistic-image.js" /* webpackChunkName: "component---src-pages-logistic-image-js" */),
  "component---src-pages-logistic-js": () => import("./../../../src/pages/logistic.js" /* webpackChunkName: "component---src-pages-logistic-js" */),
  "component---src-pages-product-images-js": () => import("./../../../src/pages/product-images.js" /* webpackChunkName: "component---src-pages-product-images-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-reporting-js": () => import("./../../../src/pages/reporting.js" /* webpackChunkName: "component---src-pages-reporting-js" */),
  "component---src-pages-request-price-js": () => import("./../../../src/pages/request-price.js" /* webpackChunkName: "component---src-pages-request-price-js" */)
}

