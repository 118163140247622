import React from 'react'

import { LocaleContext } from './src/i18n/LocaleContext'

const wrapPageElement = ({ element, props }) => {
    const { locale = 'uk' } = props.pageContext

    return (
        <LocaleContext.Provider {...props} value={locale}>
            {element}
        </LocaleContext.Provider>
    )
}

export default wrapPageElement
